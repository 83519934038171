@import "../../styles/typography.scss";

.main {
	height: 100%;
	display: grid;
	grid-template-rows: auto 1fr auto;
	align-items: center;
	row-gap: var(--spacer-sm);
}

.head {
	padding: 0 var(--gutter-x);
}

.inner,
.controls {
	padding: 0 var(--gutter-x);
}

.controls {
	padding-bottom: var(--gutter-y);
}

.options {
	columns: 4;
	gap: 20px;
	&.alt {
		columns: 5;
	}
}

.option {
	display: block;
	cursor: pointer;
	text-align: left;
	transition: opacity var(--transition);

	@include text-xl;
	.alt & {
		@include text-lg;
	}

	svg {
		width: 25px;
		fill: white;
	}

	&.dismissed {
		opacity: 0.25;
	}
}
