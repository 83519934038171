@import "../styles/global.scss";

.pageSwipeWrap {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	pointer-events: none;
	z-index: 9999;

	> div {
		transform: translateX(100%) skew(-30deg);
		position: absolute;
		top: 0;
		left: 0;
		width: 180%;
		height: 100%;

		&:nth-child(even) {
			background-color: #dcff06; //$red;
		}
		&:nth-child(odd) {
			background-color: black; // $blue;
		}
	}

	&.enter {
		> div {
			// animation-name: swipe;
			animation-duration: 3s;
			animation-fill-mode: forwards;
			// transform: translateX(-25%) skew(-30deg);
			// transition: transform 0.5s;

			&:nth-child(1) {
				animation-name: swipe1;
			}
			&:nth-child(2) {
				animation-name: swipe2;
			}
			&:nth-child(3) {
				animation-name: swipe3;
			}
			&:nth-child(4) {
				animation-name: swipe4;
			}
			&:nth-child(5) {
				animation-name: swipe5;
			}
		}
	}
	// &.leave {
	//     transform: translateX(-100%);
	// }
}

@mixin swipe($i, $in, $out) {
	$inStart: $in + "%";
	$inEnd: $in + 20 + "%";

	$outStart: $out + "%";
	$outEnd: $out + 20 + "%";

	$name: "swipe" + $i;

	@keyframes #{$name} {
		0% {
			transform: translateX(100%) skew(-30deg);
		}
		#{$inStart} {
			transform: translateX(100%) skew(-30deg);
		}
		#{$inEnd} {
			transform: translateX(-25%) skew(-30deg);
		}
		#{$outStart} {
			transform: translateX(-25%) skew(-30deg);
		}
		#{$outEnd} {
			transform: translateX(-150%) skew(-30deg);
		}

		100% {
			transform: translateX(-150%) skew(-30deg);
		}
	}
}

@include swipe(1, 1, 65);
@include swipe(2, 6, 60);
@include swipe(3, 11, 55);
@include swipe(4, 16, 50);
@include swipe(5, 21, 45);
