@import "../../styles/typography.scss";
@import "../../styles/colors.scss";

.options {
	margin-bottom: 0;
	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		margin-bottom: var(--spacer-md);
	}
}

.option {
	@include druk;
	font-size: 36px;
	line-height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	cursor: pointer;
	text-align: left;
	border-bottom: 1px solid white;
	padding: 20px 0;
	transition:
		opacity var(--transition),
		border var(--transition);
	gap: 20px;

	svg {
		width: 25px;
		fill: white;
	}

	&.dismissed {
		opacity: 0.25;
	}

	&.selected {
		border-bottom-color: $yellow;
	}

	&.disabled {
		cursor: not-allowed;
		opacity: 0.25;
	}

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		font-size: 68px;
		padding: 50px 0;
	}
}
