@import "../../styles/typography.scss";
@import "../../styles/media.scss";
@import "../../styles/colors.scss";

.main {
	--bullet-size: var(--progress-bullet-size);
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	height: var(--progress-height);

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		padding-bottom: var(--spacer-sm);
	}
}

.inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	&:first-child {
		margin-bottom: 10px;

		// BREAKPOINT
		@media (min-width: $q-breakpoint) {
			margin-bottom: 20px;
		}
	}
}

.stepNumber,
.back {
	@include neusa;
	font-size: 14px;
}

.back {
	display: flex;
	align-items: center;
	gap: 10px;
	text-transform: uppercase;
	margin-top: 20px;
	opacity: 0;
	pointer-events: none;
	transition: opacity var(--transition);
	cursor: pointer;
	&.active {
		opacity: 1;
		pointer-events: auto;
	}
	svg {
		position: relative;
		display: block;
		width: 7px;
		fill: white;
		bottom: 1px; // better vertical alignment
	}

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		display: none;
	}
}

.stepNumber {
	position: relative;
	width: var(--bullet-size);
	height: 12px;
	line-height: 12px;
	opacity: 0;
	transition:
		opacity var(--transition),
		transform var(--transition);
	transform: translateY(100%);
	&.current {
		opacity: 1;
		transform: translateY(0);
	}
	span {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}
	&:first-child {
		span {
			left: 0;
			transform: none;
		}
	}
	&:last-child {
		span {
			left: auto;
			right: 0;
			transform: none;
		}
	}
	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		font-size: 16px;
	}
}

.stepBullet {
	width: var(--bullet-size);
	height: var(--bullet-size);
	border-radius: var(--bullet-size);
	border: 1px solid rgba(white, 0.5);
	transition:
		border var(--transition),
		background var(--transition);
	&:after {
		content: attr(data-step);
	}
	&.complete {
		background: white;
		border-color: white;
	}
	&.current {
		border-color: $yellow;
	}
}

.stepBar {
	flex: 1;
	height: 1px;
	background: rgba(white, 0.5);
	&:last-child {
		display: none;
	}
}
