@import "../../styles/typography.scss";

.main {
	width: 100%;
	display: flex;
	align-items: center;
	pointer-events: none;
}

.back {
	@include neusa;
	display: none;
	align-items: center;
	gap: 10px;
	height: 44px;
	font-size: 16px;
	line-height: 16px;
	color: #eee;
	text-transform: uppercase;
	cursor: pointer;
	pointer-events: auto;
	svg {
		width: 16px;
		fill: #eee;
		margin-bottom: 2px; // slighlty better vertical alignment
	}
	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		display: flex;
	}
}

.next {
	margin-left: auto;
}
