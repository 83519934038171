@import "../../styles/spacing.scss";

.main {
	width: 348px;
	height: 208px;
	border-radius: 20px;
	border: 6px solid white;
	background: black;
	cursor: pointer;
}

.icon {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: -15px;
	right: -15px;
	width: 50px;
	height: 50px;
	background: white;
	border-radius: 50px;
	overflow: hidden;
	svg {
		width: 5px;
		fill: black;
	}
}

.figure {
	width: 100%;
	height: 100%;
	padding: $spacer $spacer-md 0;
	overflow: hidden;
}
