@import "../styles/spacing.scss";
@import "../styles/colors.scss";

.main {
	width: 100%;
	height: 100vh;
	height: 100svh;
	background-image: url("../assets/smile.jpg");
	padding: $spacer-3xl;
}

.inner {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	gap: $spacer-lg;
	background: black;
	border-radius: 20px;
	padding: $spacer-3xl;
}

.input {
	color: white;
	&:focus {
		border-color: $yellow;
	}
}
