@use "node_modules/utopia-core-scss/src/utopia" as utopia;
@import "../../styles/typography.scss";

.main {
	position: relative;
	height: 100%;
	display: grid;
	grid-template-rows: auto 1fr;
	padding: 0 var(--gutter-x);
}

.label {
	@include questionaire-label;
	padding: var(--spacer-sm) 0;
}

.inner {
	position: relative;
	--inner-gap: 24px;
	align-self: center;
	display: grid;
	row-gap: var(--inner-gap);
	padding-bottom: var(--spacer-sm);
	z-index: 10;
}

.defensive,
.offensive {
	--col-size: 150px;
	--gap: 30px;
	display: grid;
	grid-template-columns:
		repeat(4, 1fr)
		repeat(5, var(--col-size))
		repeat(4, 1fr);
	row-gap: var(--gap);
}

.defensive {
	grid-template-rows: repeat(3, 1fr);
	grid-template-areas:
		"x1 x1 x2 x2 x3 x4 x5 x6 x7 x8 x8 x9 x9"
		"y1 y1 y2 y2 y3 y4 y5 y6 y7 y8 y8 y9 y9"
		"z1 z1 z2 z2 z3 z4 z5 z6 z7 z8 z8 z9 z9";
	padding-bottom: var(--inner-gap);
	border-bottom: 1px solid white;
	transition: border var(--transition);
	.hasSelection & {
		border-color: rgba(white, 0.25);
	}
}

.offensive {
	grid-template-rows: auto 1fr;
	grid-template-areas:
		"x1 x1 x2 x2 x3 x4 x5 x6 x7 x8 x8 x9 x9"
		"y1 y1 y2 y2 y3 y4 y5 y6 y7 y8 y8 y9 y9";

	padding: 0 calc(var(--gutter-x) / 2);
}

.item {
	--icon-size: 38px;
	display: flex;
	justify-content: center;
	opacity: 1;
	transition: opacity var(--transition);
	button {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
		cursor: pointer;
	}

	span {
		@include utopia.generateClamps(
			(
				"minWidth": 1680,
				"maxWidth": 1920,
				"pairs": (
					(22, 26),
				),
				"prefix": "font-size-",
			)
		);
		@include druk;
		font-size: var(--font-size-22-26);
		text-transform: uppercase;
		display: flex;
		align-items: center;
		height: 44px;
		padding: 0 20px 2px;
		border-radius: 40px;
		white-space: nowrap;
		border: 1px solid white;
	}
	svg {
		fill: white;
		width: var(--icon-size);
		height: var(--icon-size);
	}
	&.offset {
		transform: translateY(calc(var(--icon-size) + var(--gap)));
	}
	.offensive & {
		&.offset {
			justify-content: flex-start;
			margin-bottom: 0;
			transform: translateX(calc(-50% + (var(--icon-size) / 2)));
		}
	}
	&.dismissed {
		opacity: 0.25;
	}
}

.items {
	display: flex;
	flex-direction: column;
	gap: 20px;
	.item {
		justify-content: flex-start;
		padding-left: calc((var(--col-size) - var(--icon-size)) / 2);
		button {
			flex-direction: row;
		}
	}
}

.controls {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding-left: var(--gutter-x);
	padding-right: var(--gutter-x);
	padding-bottom: var(--gutter-y);
	z-index: 20;
}
