@import "../../../styles/typography.scss";
@import "../../../styles/media.scss";

.main {
	display: grid;
	grid-template-rows: 1fr auto;
	height: 100%;
	padding: 0 var(--gutter-x);
	gap: var(--spacer-md);
	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 0;
	}
}

.inner,
.controls {
	width: 100%;
	max-width: var(--max-width);
	margin: 0 auto;
}

.inner {
	align-self: center;
	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		align-self: flex-end;
	}
}

.controls {
	align-self: flex-start;
	padding-bottom: var(--gutter-y);
	@media (min-width: $q-breakpoint) {
		padding-bottom: var(--spacer-sm);
	}
}

.label {
	@include questionaire-label;
	display: block;
}
