@import "../../styles/typography.scss";

.main {
	--padding-y: 60px;
	--padding-x: calc(var(--sidebar-width) + var(--padding-y));
	--main-height: calc(100vh - var(--head-height) - var(--progress-height));
	--mask-height: calc(var(--button-height) + (var(--gutter-y) * 2));
	position: relative;
	&.static {
		--main-height: calc(100vh - var(--head-height));
	}
}

.head {
	padding: 0 var(--padding-x);
}

.teams {
	overflow-x: hidden;
	overflow-y: auto;
	overscroll-behavior: contain;
	height: var(--main-height);
	display: grid;
	grid-template-columns: repeat(3, minmax(auto, 400px));
	grid-template-rows: auto;
	align-content: flex-start;
	justify-content: space-between;
	gap: 40px;
	padding: var(--padding-y) var(--padding-x) var(--mask-height);
}

.item {
	display: grid;
	grid-template-columns: auto 80px;
	grid-template-rows: 18px auto;
	grid-template-areas: "nickname logo" "shortname logo";
	column-gap: 20px;
	text-align: left;
	cursor: pointer;
	opacity: 1;
	transition: opacity var(--transition);
	&.dismissed {
		opacity: 0.25;
	}
}

.nickname {
	@include druk;
	text-transform: uppercase;
	font-size: 16px;
	line-height: 18px;
	grid-area: nickname;
}

.shortname {
	@include druk;
	text-transform: uppercase;
	font-size: 42px;
	line-height: 42px;
	grid-area: shortname;
}

.logo {
	grid-area: logo;
	align-self: center;
}

.image {
	width: 100%;
	height: 100%;
	object-fit: contain;
	&.error {
		display: none;
	}
}

.error {
	position: relative;
	&:hover {
		.errorB {
			pointer-events: auto;
			opacity: 1;
		}
	}
}
.errorA,
.errorB span {
	padding: 5px;
	border-radius: 5px;
	background: rgba(deeppink, 0.25);
	border: 1px solid rgba(deeppink, 0.5);
}

.errorA {
	display: block;
	font-size: 12px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.errorB {
	position: absolute;
	bottom: 100%;
	left: 50%;
	transform: translateX(-50%);
	z-index: 9999;
	opacity: 0;
	pointer-events: none;
	transition: opacity var(--transition);
	padding-bottom: 10px;
}

.mask {
	position: fixed;
	bottom: 0;
	left: 0;
	height: var(--mask-height);
	width: 100%;
	background: pink;
	pointer-events: none;
	z-index: 10;
	backdrop-filter: blur(1px);
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 1) 100%
	);
}

.controls {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding-left: var(--padding-x);
	padding-right: var(--gutter-x);
	padding-bottom: var(--gutter-y);
	z-index: 20;
}
