@import "../../../styles/typography.scss";
@import "../../../styles/colors.scss";

.main {
	position: relative;
	height: calc(100vh - var(--progress-height));
	height: calc(100svh - var(--progress-height));
	padding: 0 var(--gutter-x);
	// padding-bottom: calc(var(--button-height) + (var(--gutter-y) * 2));
}

.head {
	display: flex;
	align-items: center;
	height: var(--head-height);
}

.label,
.title {
	@include druk;
	text-transform: uppercase;
	font-size: 24px;
	line-height: 100%;
}

.list {
	overflow-x: hidden;
	overflow-y: auto;
	overscroll-behavior: contain;
	--calc: var(--head-height) - var(--progress-height);
	height: calc(100vh - var(--calc));
	height: calc(100svh - var(--calc));
	padding-bottom: calc(var(--button-height) + (var(--gutter-y) * 2));
}

.border {
	border-top: 3px solid rgba(white, 0.25);
}

.title {
	position: sticky;
	top: 0;
	padding: 20px 20px 22px;
	border-top: 3px solid rgba(white, 0.25);
	border-bottom: 1px solid rgba(white, 0.25);
	backdrop-filter: blur(4px);
	z-index: 20;
}

.item {
	@include neusa;
	position: relative;
	display: block;
	text-transform: uppercase;
	width: 100%;
	font-size: 16px;
	text-align: left;
	padding: 30px 20px;
	border-bottom: 1px solid rgba(white, 0.25);
	border-left: 1px solid transparent;
	cursor: pointer;
	transition:
		border var(--transition),
		color var(--transition);
	z-index: 10;

	&.dismissed {
		color: rgba(white, 0.25);
	}

	&.selected {
		border-left-color: $yellow;
	}
}

.controls {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding-left: var(--gutter-x);
	padding-right: var(--gutter-x);
	padding-bottom: var(--gutter-y);
	z-index: 20;
}
