@import "../../../styles/colors.scss";
@import "../../../styles/spacing.scss";

.main {
	--fill: black;
	--stroke: white;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	&.modal {
		height: 100%;
		padding: $spacer-lg;
	}
	svg {
		width: auto;
		height: 100%;
	}
}

.item {
	fill: var(--fill);
	stroke: var(--stroke);
	stroke-width: 2;
}

.stroke {
	stroke: var(--stroke);
}

.fill {
	fill: var(--stroke);
}

.red {
	fill: $red;
}

.yellow {
	fill: $yellow;
}
