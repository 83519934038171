@import "../../styles/typography.scss";

.main {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: var(--spacer-sm);

	padding: 0 var(--gutter-x);
	padding-bottom: calc(var(--button-height) + (var(--gutter-y) * 2));

	text-align: center;

	@media (min-width: $q-breakpoint) {
		padding: var(--spacer-sm) var(--gutter-x);
		gap: var(--spacer-md);
	}
}

.heading {
	@include text-base-druk;

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		@include text-3xl;
	}
}

.items {
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	gap: var(--spacer-sm);
	width: 100%;
	max-width: 640px;
	margin: 0 auto;

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		gap: var(--spacer-md);
		max-width: 100%;
	}
}

.item {
	grid-column: 1 / -1;

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		grid-column: span 1 / span 1;
	}
}

.image {
	aspect-ratio: 16 / 9;
	background: rgba(white, 0.1);
	border-radius: 5px;
	margin-bottom: var(--spacer-sm);
}

.text {
	@include text-sm;

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		@include text-base;
	}
}

.controls {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding-left: var(--gutter-x);
	padding-right: var(--gutter-x);
	padding-bottom: var(--gutter-y);
	z-index: 20;

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		position: static;
		display: flex;
		justify-content: center;
		padding: 0;
	}
}
