@import "../../styles/typography.scss";

.main {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: var(--sidebar-width);
	height: 100%;
	background: black;
	border-right: 1px solid rgba(white, 0.25);
	z-index: 999;
	@media (min-width: $q-breakpoint) {
		display: block;
	}
}

.label {
	display: flex;
	align-items: end;
	justify-content: center;
	padding-bottom: 24px;
	// align bottom of div with the progress bar
	height: calc(
		var(--progress-height) - (var(--progress-bullet-size) / 2) - var(
				--spacer-sm
			)
	);
	span {
		@include druk;
		text-transform: uppercase;
		font-size: 16px;
	}
}

.state {
	display: flex;
	justify-content: center;
	span {
		@include druk;
		text-transform: uppercase;
		font-size: 42px;
		writing-mode: vertical-lr;
		transform: rotate(180deg);
	}
}
