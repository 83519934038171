@import "../styles/typography.scss";
@import "../styles/colors.scss";
@import "../styles/spacing.scss";

.main {
	position: relative;
	width: 100%;
	height: 100%;
	h2 {
		position: absolute;
		top: $spacer-lg;
		left: $spacer-lg;
		@include text-xl;
		z-index: 20;
	}
	img {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 10;
		object-fit: cover;
		object-position: center bottom;
	}
}
