@import "./media.scss";

@font-face {
	font-family: "Druk";
	src:
		url("/assets/fonts/druk/druk-bold-webfont.woff2") format("woff2"),
		url("/assets/fonts/druk/druk-bold-webfont.woff") format("woff");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Neusa";
	src:
		url("/assets/fonts/neusa/NeusaNextStdTrial-Regular.woff2") format("woff2"),
		url("/assets/fonts/neusa/NeusaNextStdTrial-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@mixin druk {
	leading-trim: both;
	text-edge: cap;
	font-family: Druk;
	font-style: normal;
	font-weight: 700;
	line-height: 120%;
}

@mixin neusa {
	font-family: Neusa;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
}

@mixin text-4xl {
	@include druk;
	font-size: 287px;
}

.text-4xl {
	@include text-4xl;
}

@mixin text-3xl {
	@include druk;
	font-size: 110px;
}

.text-3xl {
	@include text-3xl;
}

@mixin text-2xl {
	@include druk;
	font-size: 68px;
}

.text-2xl {
	@include text-2xl;
}

@mixin text-xl {
	@include druk;
	font-size: 68px;
}

.text-xl {
	@include text-xl;
}

@mixin text-lg {
	@include druk;
	font-size: 42px;
	line-height: 130%; /* 54.6px */
	text-transform: uppercase;
}

.text-lg {
	@include text-lg;
}

@mixin text-base-druk {
	@include druk;
	font-size: 26px;
	text-transform: uppercase;
}
.text-base-druk {
	@include text-base-druk;
}

@mixin text-base {
	@include neusa;
	font-size: 26px;
}
.text-base {
	@include text-base;
}

@mixin text-sm {
	@include neusa;
	font-size: 16px;
}
.text-sm {
	@include text-sm;
}

@mixin text-xs {
	@include neusa;
	font-size: 10px;
}
.text-xs {
	@include text-xs;
}

// component
@mixin questionaire-label {
	@include neusa;
	font-size: 16px;
	text-transform: uppercase;
	line-height: 120%;
	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		font-size: 26px;
	}
}
