@import "../styles/global.scss";

.button {
	@include druk;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
	color: black;
	font-size: 26px;
	line-height: 26px;
	letter-spacing: 1.26px;
	text-transform: uppercase;
	border-radius: 9999px;
	background: white;
	cursor: pointer;
	padding: 20px 40px;

	&.placeholder {
		background: none;
		color: $yellow;
		border: 2px dashed $yellow;
	}

	@media (min-width: $q-breakpoint) {
		font-size: 42px;
		line-height: 42px;
		padding: 30px 70px;
	}
	&.light {
		background: white;
		color: black;
	}
}
