@import "../../styles/spacing.scss";

.main {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: $spacer-xl $spacer-3xl;
	background: rgba(black, 0.5);
	backdrop-filter: blur(1px);
	z-index: 9999;
}
