@import "../styles/spacing.scss";

.main {
	position: relative;
	height: 100vh;
	height: 100svh;
	img {
		position: relative;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: left bottom;
		z-index: 10;
	}
}

.button {
	position: absolute;
	bottom: $spacer-2xl;
	right: $spacer-lg;
	z-index: 20;
}
