@import "../styles/spacing.scss";
@import "../styles/typography.scss";
@import "../styles/colors.scss";

.main {
	width: 100%;
	height: 100vh;
	height: 100svh;
	color: white;
	text-align: center;
	display: grid;
	grid-template-rows: 1fr auto auto;
	gap: $spacer-lg;
	padding: $spacer-lg;
	background-image: url("../assets/smile.jpg");
}

.rowA,
.rowB,
.rowC {
	justify-self: center;
}

.rowA {
}

.rowB {
}

.rowC {
}

.rowA {
	&.ready {
		align-self: center;
		h2 {
			@include text-lg;
			line-height: 100%;
			margin-bottom: $spacer-sm;
		}
		h3 {
			@include text-4xl;
			line-height: 85%;
		}
	}
}

.rowB {
	&.ready {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: $spacer-md;
		h4 {
			@include text-lg;
			line-height: 100%;
		}
		svg {
			fill: white;
			width: 34px;
		}
	}
}

.countdown {
	grid-row: span 2 / span 2;
	h2 {
		@include druk;
		text-transform: uppercase;
		font-size: 148px;
		line-height: 100%;
	}
	h3 {
		@include druk;
		color: black;
		-webkit-text-stroke-width: 10px;
		-webkit-text-stroke-color: $yellow;
		font-family: Druk;
		font-size: 662px;
		line-height: 90%;
	}
}

.snap {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: black;
	z-index: 9999;
}

.button {
	position: relative;
	width: 177px;
	height: 177px;
	> svg {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 10;
		opacity: 0;
		transition: opacity var(--transition);
	}
	button {
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		width: 110px;
		height: 110px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: white;
		border-radius: 9999px;
		opacity: 1;
		transition: opacity var(--transition);
		transform: translate(-50%, -50%);
		z-index: 20;
		> svg {
			fill: black;
			width: 42px;
		}
	}
	&.ready {
		svg {
			opacity: 1;
		}
		button {
			opacity: 0.25;
		}
	}
}
