@import "../styles/typography.scss";
@import "../styles/colors.scss";
@import "../styles/spacing.scss";

.main {
	width: 100%;
	height: 100%;
}

.items {
	height: 100%;
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	align-items: center;
	gap: $spacer-xl;
	padding: 0 $spacer-3xl;
}

.item {
	text-align: center;
}

.image {
	position: relative;
	width: 90%;
	aspect-ratio: 1/1;
	background: black;
	border-radius: 9999px;
	margin: 0 auto 30px;
}

.state {
	position: absolute;
	bottom: 12px;
	right: 12px;
	width: calc(114 / 460 * 90%);
	display: flex;
	align-items: center;
	justify-content: center;
	aspect-ratio: 1/1;
	border-radius: 9999px;
	&.yes {
		background: $yellow;
		svg {
			width: 55%;
			fill: black;
		}
	}
	&.no {
		background: $red;
		svg {
			width: 45%;
		}
	}
	svg {
		fill: white;
	}
}

.text {
	@include druk;
	// https://utopia.fyi/clamp/calculator?a=1620,1920,42%E2%80%9462
	font-size: clamp(42px, -66px + 6.6667vi, 62px);
	line-height: 100%;
}
