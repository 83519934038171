@import "../../styles/colors.scss";

.main {
	position: relative;
	min-height: 100vh;
	min-height: 100svh;
	background-image: url("../../assets/texture.jpg");
	background-size: cover;
	background-position: bottom right;
	background-color: black;
	background-repeat: no-repeat;
}

.inner {
	position: relative;
	min-height: 100vh;
	min-height: 100svh;
	z-index: 10;
}

.info {
	position: absolute;
	bottom: 60px;
	right: 60px;
	z-index: 20;
}
