@import "../../styles/colors.scss";
@import "../../styles/typography.scss";
@import "../../styles/spacing.scss";

.main {
	--info-width: 0;
	display: grid;
	grid-template-columns: var(--info-width) 1fr var(--info-width);
	grid-template-rows: auto 1fr;
	grid-template-areas:
		"top top top"
		"left center right";
	justify-items: center;
	min-height: 100vh;
	min-height: 100svh;
	gap: $spacer;
	padding: $spacer-md;
	color: white;
	background: $slate;

	&.isModal {
		grid-template-columns: repeat(1, minmax(0, 1fr));
		grid-template-rows: auto 1fr;
		grid-template-areas: "top" "bottom";
		width: 100%;
		max-width: 1680px;
		min-height: auto;
		padding: $spacer-md;
		border-radius: 38px;
		box-shadow: 0 4px 54px 0 rgba(0, 0, 0, 0.55);
	}

	&.hasInfo {
		--info-width: 312px;
	}
}

.inner {
	position: relative;
	grid-area: top;
	aspect-ratio: 1800 / 720;
	width: 100%;
	max-width: 1920px;
	background-image: url("../../assets/grid.jpg");
	background-size: cover;
	background-position: bottom right;
	background-color: black;
	background-repeat: no-repeat;
	border-radius: 20px;
	overflow: hidden;
	.isModal & {
		aspect-ratio: 1550 / 578;
	}
}

.controls {
	grid-area: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: $spacer;
	text-align: center;
	.isModal & {
		grid-area: bottom;
	}
}

.text {
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	@include druk;
	font-size: 42px;
	text-transform: uppercase;
}

.info {
	grid-area: right;
	align-self: center;
	width: var(--info-width);
	height: 180px;
	background: black;
	border-radius: 18px;
}
