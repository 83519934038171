@import "../styles/media.scss";

.main {
	--gutter-x: 20px;
	--gutter-y: 20px;

	--spacer-sm: 15px;
	--spacer-md: 30px;
	--spacer-lg: 40px;

	--max-width: 1120px;
	--progress-height: 90px;
	--progress-bullet-size: 8px;
	--sidebar-width: 80px;
	--head-height: 80px;
	--button-height: 44px;

	display: grid;
	grid-template-rows: auto 1fr;
	min-height: 100vh;
	min-height: 100svh;

	color: white;
	background-image: url("../assets/texture-mobile.jpg");
	background-size: cover;
	background-position: bottom center;
	background-color: black;
	background-repeat: no-repeat;

	// BREAKPOINT
	@media (min-width: $q-breakpoint) {
		--gutter-x: 100px;
		--gutter-y: 50px;

		--spacer-sm: 30px;
		--spacer-md: 60px;
		--spacer-lg: 80px;

		--progress-height: 120px;
		--progress-bullet-size: 8px;
		--head-height: 160px;
		--button-height: 90px;

		background-position: bottom right;
		background-image: url("../assets/texture.jpg");
	}
}

.progress {
	padding: 0 var(--gutter-x);
}

// .container {
// }

.helper {
	display: none;
	position: fixed;
	top: 20px;
	right: 20px;
	font-size: 10px;
	background: rgba(deeppink, 0.15);
	padding: 10px;
	border-radius: 10px;
	z-index: 9999;
	pointer-events: none;
	backdrop-filter: blur(2px);
	span {
		text-transform: uppercase;
	}
	&:empty {
		display: none;
	}
	@media (min-width: $q-breakpoint) {
		display: block;
	}
}
